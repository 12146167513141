<template>
  <div class="lose404-page">
    <img src="../assets/images/lose.png" alt="" />
    <span class="text">链接已经失效</span>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "notFound",
  created() {
    this.setLoading(false);
  },
  methods: {
    ...mapMutations(["setLoading"]),
  },
};
</script>

<style lang="less" scoped>
.lose404-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;

  .text {
    font-size: 0.3rem;
    color: #ccc;
  }
}
</style>